import $ from 'jquery';
import { Context } from '../';
import Writer from '../../js/Writer';

declare global {
  interface Window {
    writer: Writer;
  }
}

export const setToken = ({ state }: Context, token: string) => {
  state.document.GitHubToken = token;
};

export const setOwner = ({ state }: Context, owner: string) => {
  state.document.GitHubOwner = owner;
};

export const setRepo = ({ state }: Context, repo: string) => {
  state.document.GitHubRepo = repo;
};

export const setFromGitHub = ({ state }: Context, FromGitHub: boolean) => {
  state.document.FromGitHub = FromGitHub;
};

export const setIsPrivate = ({ state }: Context, isPrivate: boolean) => {
  state.document.isPrivate = isPrivate;
};

export const getIsPrivate = ({ state }: Context) => {
  return (state.document.isPrivate)
};

export const setInitialStateSchema = ({ state }: Context, id: string) => {
  state.document.schemaId = id;
};

export const setLoaded = ({ state }: Context, value: boolean) => {
  state.document.loaded = value;
};

export const setRootname = ({ state }: Context, value: string) => {
  state.document.rootName = value;
};

export const setSchema = ({ state }: Context, id: string) => {
  window.writer?.event('schemaChanged').publish(id);

  state.document.schemaId = id;
  return state.editor.schemasList.find((schema) => schema.id === id);
};

export const setDocumentUrl = ({ state }: Context, url?: string) => {
  // Strip url of eventual parameters like token
  if (url) {
    state.document.url = url.split('?')[0];
  } else {
    state.document.url = undefined
  }
};

export const updateContent = ({ state }: Context, content: string) => {
  if (!state.document.xml) return;
  state.editor.contentHasChanged = true;
  state.document.xml = content;
};

export const updateXMLHeader = ({ state }: Context, content: string) => {
  const parser = new DOMParser();

  const xml = parser.parseFromString(content, 'application/xml');
  const errorNode = xml.querySelector('parsererror');

  if (errorNode) {
    window.writer.dialogManager.show('message', {
      title: 'Invalid XML',
      msg: 'There was an error parsing the XML.',
      type: 'error',
    });
    return false;
  }

  let newHeaderString = '';

  $(xml)
    .find(window.writer.schemaManager.getHeader())
    .children()
    .each((index, element) => {
      newHeaderString += window.writer.converter.buildEditorString(element);
    });

  const _header = $(
    `[_tag="${window.writer.schemaManager.getHeader()}"]`,
    window.writer.editor?.getBody(),
  );

  _header.html(newHeaderString);

  state.editor.contentHasChanged = true;
};

export const clear = ({ state }: Context) => {
  state.document.loaded = false;
  state.document.rootName = undefined;
  state.document.schemaId = '';
  state.document.loaded = false;
  state.document.url = undefined;
  state.document.xml = undefined;
  state.document.isReload = false;
};

export const loadDocumentXML = ({ actions, state }: Context, content: string) => {
  window.writer?.loadDocumentXML(content);
  actions.document.updateContent(content);
  if (state.document.isReload) {
    state.editor.contentHasChanged = true;
  }
};

export const setDocumentTouched = ({ state }: Context, value: boolean) => {
  state.document.touched = value;
};

export const setIsReload = ({ state }: Context, value: boolean) => {
  state.document.isReload = value;
};
