import axios from 'axios';

export const api = {
  /**
   * Get  Google Analytics Measurement ID from the server
   * @returns The Google Analytics Measurement ID. Empty for hda-LEAFWriter, since we removed Google Analytics.
   */
  async getGAID() {
    return;
  },
};
