import { createSvgIcon } from '@mui/material/utils';

export const BookIcon = createSvgIcon(
  <path
    d="M19,15.2V4.8C19,4.3,18.7,4,18.2,4H8C6.3,4,5,5.3,5,7v10c0,1.7,1.3,3,3,3h10.2c0.4,0,0.8-0.3,0.8-0.8v-0.5
	c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.5-0.1-1.9,0-2.3C18.9,15.7,19,15.5,19,15.2L19,15.2z M9,8.2C9,8.1,9.1,8,9.2,8h6.6
	C15.9,8,16,8.1,16,8.2v0.6C16,8.9,15.9,9,15.8,9H9.2C9.1,9,9,8.9,9,8.8V8.2z M9,10.2C9,10.1,9.1,10,9.2,10h6.6
	c0.1,0,0.2,0.1,0.2,0.2v0.6c0,0.1-0.1,0.2-0.2,0.2H9.2C9.1,11,9,10.9,9,10.8V10.2z M16.9,18H8c-0.6,0-1-0.4-1-1s0.5-1,1-1h8.9
	C16.9,16.5,16.9,17.5,16.9,18z"
  />,
  'Book',
);

export const BookOutlinedIcon = createSvgIcon(
  <g>
    <path d="M5.81,0C3.25,0,1.18,2.08,1.18,4.64v15.46c0,2.56,2.08,3.9,4.64,3.9h15.46c.86,0,1.55-.29,1.55-1.15s-.69-1.2-1.55-1.2v-3.09c.86,0,1.55-.69,1.55-1.55V1.55c0-.86-.69-1.55-1.55-1.55H5.81Z" />
    <path
      d="M5.93,18.56h13.31v3.09H5.93c-.92,0-1.66-.69-1.66-1.55s.74-1.55,1.66-1.55Z"
      fill="#fff"
    />
    <path
      d="M8.62,1.6h11.33c.75,0,1.36,.61,1.36,1.36V15.62c0,.75-.61,1.36-1.36,1.36H8.62V1.6h0Z"
      fill="#fff"
    />
    <path
      d="M7.14,16.95c-.83,0-4.34-.21-4.3,2.13V5.6c0-2.2,1.92-3.98,4.3-3.98h0v15.32Z"
      fill="#fff"
    />
    <path d="M10.12,6.96c0-.43,.3-.77,.67-.77h8.07c.37,0,.67,.35,.67,.77s-.3,.77-.67,.77H10.79c-.37,0-.67-.35-.67-.77Z" />
    <path d="M10.79,9.28h8.07c.37,0,.67,.35,.67,.77s-.3,.77-.67,.77H10.79c-.37,0-.67-.35-.67-.77s.3-.77,.67-.77Z" />
  </g>,
  'BookOutlined',
);
