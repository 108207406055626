import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './locales/de-DE.json'
import en from './locales/en-CA.json';
import fr from './locales/fr-CA.json';

export const resources = { en, fr, de } as const;

void i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    defaultNS: 'LWC',
    fallbackLng: 'en-CA',
    ns: ['LWC'],
    resources,
    returnEmptyString: false,
  });
