import { Box, Link, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import pck from '../../../package.json';
import { useAppState } from '../../overmind';
import { ValdidationErrors } from './ValdidationErrors';
import AnnotationMode from './annotationMode';
import EditorMode from './editorMode';
import { Schema } from './schema';

export const BottomBar = () => {
  const { isReadonly } = useAppState().editor;
  const { validationErrors } = useAppState().validator;
  const { t } = useTranslation('leafwriter');
  const version = pck.version;

  return (
    <Paper
      elevation={0}
      square
      sx={{
        width: '100%',
        bgcolor: ({ palette }) => (palette.mode === 'dark' ? palette.background.paper : '#f5f5f5'),
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} px={2}>
        {!isReadonly && (
          <>
            <EditorMode />
            <AnnotationMode />
            <Schema />
          </>
        )}

        {validationErrors > 0 && !isReadonly && <ValdidationErrors />}

        <Box flexGrow={1} />

        <Link
          color="text.secondary"
          variant="caption"
          href="https://code.fbi.h-da.de/leaf-writer-x/leafwriter-x/-/issues"
          target="_blank"
        >
          {t('Bugs')} / {t('Requests')}
        </Link>

        <Link
          color="text.secondary"
          variant="caption"
          href={pck.homepage}
          rel="noopener"
          target="_blank"
          title="Repository"
        >
          {`LEAF-Writer ${version}`}
        </Link>
        <Link
          color="text.secondary"
          variant="caption"
          href="https://www.tiny.cloud"
          target="_blank"
          rel="noopener"
          title={t('Powered by').toString()}
        >
          {t('Powered by')} Tiny
        </Link>
      </Stack>
    </Paper>
  );
};
