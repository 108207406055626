export const defaultRoles = [
  { value: '', label: '(none)' },
  { value: 'other', label: 'Other' },
  { value: 'abr', label: 'Abridger' },
  { value: 'acp', label: 'Art copyist' },
  { value: 'act', label: 'Actor' },
  { value: 'adi', label: 'Art director' },
  { value: 'adp', label: 'Adapter' },
  { value: 'aft', label: 'Author of afterword, colophon, etc.' },
  { value: 'anl', label: 'Analyst' },
  { value: 'anm', label: 'Animator' },
  { value: 'ann', label: 'Annotator' },
  { value: 'ant', label: 'Bibliographic antecedent' },
  { value: 'ape', label: 'Appellee' },
  { value: 'apl', label: 'Appellant' },
  { value: 'app', label: 'Applicant' },
  { value: 'aqt', label: 'Author in quotations or text abstracts' },
  { value: 'arc', label: 'Architect' },
  { value: 'ard', label: 'Artistic director' },
  { value: 'arr', label: 'Arranger' },
  { value: 'art', label: 'Artist' },
  { value: 'asg', label: 'Assignee' },
  { value: 'asn', label: 'Associated name' },
  { value: 'ato', label: 'Autographer' },
  { value: 'att', label: 'Attributed name' },
  { value: 'auc', label: 'Auctioneer' },
  { value: 'aud', label: 'Author of dialog' },
  { value: 'aui', label: 'Author of introduction, etc.' },
  { value: 'aus', label: 'Screenwriter' },
  { value: 'aut', label: 'Author' },
  { value: 'bdd', label: 'Binding designer' },
  { value: 'bjd', label: 'Bookjacket designer' },
  { value: 'bkd', label: 'Book designer' },
  { value: 'bkp', label: 'Book producer' },
  { value: 'blw', label: 'Blurb writer' },
  { value: 'bnd', label: 'Binder' },
  { value: 'bpd', label: 'Bookplate designer' },
  { value: 'brd', label: 'Broadcaster' },
  { value: 'brl', label: 'Braille embosser' },
  { value: 'bsl', label: 'Bookseller' },
  { value: 'cas', label: 'Caster' },
  { value: 'ccp', label: 'Conceptor' },
  { value: 'chr', label: 'Choreographer' },
  { value: 'cli', label: 'Client' },
  { value: 'cll', label: 'Calligrapher' },
  { value: 'clr', label: 'Colorist' },
  { value: 'clt', label: 'Collotyper' },
  { value: 'cmm', label: 'Commentator' },
  { value: 'cmp', label: 'Composer' },
  { value: 'cmt', label: 'Compositor' },
  { value: 'cnd', label: 'Conductor' },
  { value: 'cng', label: 'Cinematographer' },
  { value: 'cns', label: 'Censor' },
  { value: 'coe', label: 'Contestant-appellee' },
  { value: 'col', label: 'Collector' },
  { value: 'com', label: 'Compiler' },
  { value: 'con', label: 'Conservator' },
  { value: 'cor', label: 'Collection registrar' },
  { value: 'cos', label: 'Contestant' },
  { value: 'cot', label: 'Contestant-appellant' },
  { value: 'cou', label: 'Court governed' },
  { value: 'cov', label: 'Cover designer' },
  { value: 'cpc', label: 'Copyright claimant' },
  { value: 'cpe', label: 'Complainant-appellee' },
  { value: 'cph', label: 'Copyright holder' },
  { value: 'cpl', label: 'Complainant' },
  { value: 'cpt', label: 'Complainant-appellant' },
  { value: 'cre', label: 'Creator' },
  { value: 'crp', label: 'Correspondent' },
  { value: 'crr', label: 'Corrector' },
  { value: 'crt', label: 'Court reporter' },
  { value: 'csl', label: 'Consultant' },
  { value: 'csp', label: 'Consultant to a project' },
  { value: 'cst', label: 'Costume designer' },
  { value: 'ctb', label: 'Contributor' },
  { value: 'cte', label: 'Contestee-appellee' },
  { value: 'ctg', label: 'Cartographer' },
  { value: 'ctr', label: 'Contractor' },
  { value: 'cts', label: 'Contestee' },
  { value: 'ctt', label: 'Contestee-appellant' },
  { value: 'cur', label: 'Curator' },
  { value: 'cwt', label: 'Commentator for written text' },
  { value: 'dbp', label: 'Distribution place' },
  { value: 'dfd', label: 'Defendant' },
  { value: 'dfe', label: 'Defendant-appellee' },
  { value: 'dft', label: 'Defendant-appellant' },
  { value: 'dgg', label: 'Degree granting institution' },
  { value: 'dis', label: 'Dissertant' },
  { value: 'dln', label: 'Delineator' },
  { value: 'dnc', label: 'Dancer' },
  { value: 'dnr', label: 'Donor' },
  { value: 'dpc', label: 'Depicted' },
  { value: 'dpt', label: 'Depositor' },
  { value: 'drm', label: 'Draftsman' },
  { value: 'drt', label: 'Director' },
  { value: 'dsr', label: 'Designer' },
  { value: 'dst', label: 'Distributor' },
  { value: 'dtc', label: 'Data contributor' },
  { value: 'dte', label: 'Dedicatee' },
  { value: 'dtm', label: 'Data manager' },
  { value: 'dto', label: 'Dedicator' },
  { value: 'dub', label: 'Dubious author' },
  { value: 'edc', label: 'Editor of compilation' },
  { value: 'edm', label: 'Editor of moving image work' },
  { value: 'edt', label: 'Editor' },
  { value: 'egr', label: 'Engraver' },
  { value: 'elg', label: 'Electrician' },
  { value: 'elt', label: 'Electrotyper' },
  { value: 'eng', label: 'Engineer' },
  { value: 'enj', label: 'Enacting jurisdiction' },
  { value: 'etr', label: 'Etcher' },
  { value: 'evp', label: 'Event place' },
  { value: 'exp', label: 'Expert' },
  { value: 'fac', label: 'Facsimilist' },
  { value: 'fds', label: 'Film distributor' },
  { value: 'fld', label: 'Field director' },
  { value: 'flm', label: 'Film editor' },
  { value: 'fmd', label: 'Film director' },
  { value: 'fmk', label: 'Filmmaker' },
  { value: 'fmo', label: 'Former owner' },
  { value: 'fmp', label: 'Film producer' },
  { value: 'fnd', label: 'Funder' },
  { value: 'fpy', label: 'First party' },
  { value: 'frg', label: 'Forger' },
  { value: 'gis', label: 'Geographic information specialist' },
  { value: 'his', label: 'Host institution' },
  { value: 'hnr', label: 'Honoree' },
  { value: 'hst', label: 'Host' },
  { value: 'ill', label: 'Illustrator' },
  { value: 'ilu', label: 'Illuminator' },
  { value: 'ins', label: 'Inscriber' },
  { value: 'itr', label: 'Instrumentalist' },
  { value: 'ive', label: 'Interviewee' },
  { value: 'ivr', label: 'Interviewer' },
  { value: 'inv', label: 'Inventor' },
  { value: 'isb', label: 'Issuing body' },
  { value: 'jud', label: 'Judge' },
  { value: 'jug', label: 'Jurisdiction governed' },
  { value: 'lbr', label: 'Laboratory' },
  { value: 'lbt', label: 'Librettist' },
  { value: 'ldr', label: 'Laboratory director' },
  { value: 'led', label: 'Lead' },
  { value: 'lee', label: 'Libelee-appellee' },
  { value: 'lel', label: 'Libelee' },
  { value: 'len', label: 'Lender' },
  { value: 'let', label: 'Libelee-appellant' },
  { value: 'lgd', label: 'Lighting designer' },
  { value: 'lie', label: 'Libelant-appellee' },
  { value: 'lil', label: 'Libelant' },
  { value: 'lit', label: 'Libelant-appellant' },
  { value: 'lsa', label: 'Landscape architect' },
  { value: 'lse', label: 'Licensee' },
  { value: 'lso', label: 'Licensor' },
  { value: 'ltg', label: 'Lithographer' },
  { value: 'lyr', label: 'Lyricist' },
  { value: 'mcp', label: 'Music copyist' },
  { value: 'mdc', label: 'Metadata contact' },
  { value: 'mfp', label: 'Manufacture place' },
  { value: 'mfr', label: 'Manufacturer' },
  { value: 'mod', label: 'Moderator' },
  { value: 'mon', label: 'Monitor' },
  { value: 'mrb', label: 'Marbler' },
  { value: 'mrk', label: 'Markup editor' },
  { value: 'msd', label: 'Musical director' },
  { value: 'mte', label: 'Metal-engraver' },
  { value: 'mus', label: 'Musician' },
  { value: 'nrt', label: 'Narrator' },
  { value: 'opn', label: 'Opponent' },
  { value: 'org', label: 'Originator' },
  { value: 'orm', label: 'Organizer of meeting' },
  { value: 'osp', label: 'Onscreen presenter' },
  { value: 'oth', label: 'Other' },
  { value: 'own', label: 'Owner' },
  { value: 'pan', label: 'Panelist' },
  { value: 'pat', label: 'Patron' },
  { value: 'pbd', label: 'Publishing director' },
  { value: 'pbl', label: 'Publisher' },
  { value: 'pdr', label: 'Project director' },
  { value: 'pfr', label: 'Proofreader' },
  { value: 'pht', label: 'Photographer' },
  { value: 'plt', label: 'Platemaker' },
  { value: 'pma', label: 'Permitting agency' },
  { value: 'pmn', label: 'Production manager' },
  { value: 'pop', label: 'Printer of plates' },
  { value: 'ppm', label: 'Papermaker' },
  { value: 'ppt', label: 'Puppeteer' },
  { value: 'pra', label: 'Praeses' },
  { value: 'prc', label: 'Process contact' },
  { value: 'prd', label: 'Production personnel' },
  { value: 'pre', label: 'Presenter' },
  { value: 'prf', label: 'Performer' },
  { value: 'prg', label: 'Programmer' },
  { value: 'prm', label: 'Printmaker' },
  { value: 'prn', label: 'Production company' },
  { value: 'pro', label: 'Producer' },
  { value: 'prp', label: 'Production place' },
  { value: 'prs', label: 'Production designer' },
  { value: 'prt', label: 'Printer' },
  { value: 'prv', label: 'Provider' },
  { value: 'pta', label: 'Patent applicant' },
  { value: 'pte', label: 'Plaintiff-appellee' },
  { value: 'ptf', label: 'Plaintiff' },
  { value: 'pth', label: 'Patent holder' },
  { value: 'ptt', label: 'Plaintiff-appellant' },
  { value: 'pup', label: 'Publication place' },
  { value: 'rbr', label: 'Rubricator' },
  { value: 'rce', label: 'Recording engineer' },
  { value: 'rcd', label: 'Recordist' },
  { value: 'rcp', label: 'Addressee' },
  { value: 'rdd', label: 'Radio director' },
  { value: 'red', label: 'Redaktor' },
  { value: 'ren', label: 'Renderer' },
  { value: 'res', label: 'Researcher' },
  { value: 'rev', label: 'Reviewer' },
  { value: 'rpc', label: 'Radio producer' },
  { value: 'rps', label: 'Repository' },
  { value: 'rpt', label: 'Reporter' },
  { value: 'rpy', label: 'Responsible party' },
  { value: 'rse', label: 'Respondent-appellee' },
  { value: 'rsg', label: 'Restager' },
  { value: 'rsp', label: 'Respondent' },
  { value: 'rsr', label: 'Restorationist' },
  { value: 'rst', label: 'Respondent-appellant' },
  { value: 'rth', label: 'Research team head' },
  { value: 'rtm', label: 'Research team member' },
  { value: 'sad', label: 'Scientific advisor' },
  { value: 'sce', label: 'Scenarist' },
  { value: 'scl', label: 'Sculptor' },
  { value: 'scr', label: 'Scribe' },
  { value: 'sds', label: 'Sound designer' },
  { value: 'sec', label: 'Secretary' },
  { value: 'sgd', label: 'Stage director' },
  { value: 'sgn', label: 'Signer' },
  { value: 'sht', label: 'Supporting host' },
  { value: 'sll', label: 'Seller' },
  { value: 'sng', label: 'Singer' },
  { value: 'spk', label: 'Speaker' },
  { value: 'spn', label: 'Sponsor' },
  { value: 'spy', label: 'Second party' },
  { value: 'std', label: 'Set designer' },
  { value: 'stg', label: 'Setting' },
  { value: 'stl', label: 'Storyteller' },
  { value: 'stm', label: 'Stage manager' },
  { value: 'stn', label: 'Standards body' },
  { value: 'str', label: 'Stereotyper' },
  { value: 'srv', label: 'Surveyor' },
  { value: 'tcd', label: 'Technical director' },
  { value: 'tch', label: 'Teacher' },
  { value: 'ths', label: 'Thesis advisor' },
  { value: 'tld', label: 'Television director' },
  { value: 'tlp', label: 'Television producer' },
  { value: 'trc', label: 'Transcriber' },
  { value: 'trl', label: 'Translator' },
  { value: 'tyd', label: 'Type designer' },
  { value: 'tyg', label: 'Typographer' },
  { value: 'uvp', label: 'University place' },
  { value: 'vdg', label: 'Videographer' },
  { value: 'wac', label: 'Writer of added commentary' },
  { value: 'wal', label: 'Writer of added lyrics' },
  { value: 'wam', label: 'Writer of accompanying material' },
  { value: 'wat', label: 'Writer of added text' },
  { value: 'wdc', label: 'Woodcutter' },
  { value: 'wde', label: 'Wood engraver' },
  { value: 'wit', label: 'Witness' },
];
