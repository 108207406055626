import { logHttpError } from '@src/services/utilities';
import type { Resource } from '@src/types';
import axios from 'axios';

export const api = {
  /**
   * Get a colleciton of document: either template or sample files
   * @param {string} collection - string - The name of the collection you want to load.
   * @returns An array of Resource objects.
   */
  async loadCollection(collection: string) {
    try {
      const { data } = await axios.get<Resource[]>(`./content/${collection}.json`);
      return data;
    } catch (error) {
      logHttpError(error);
      if (axios.isAxiosError(error)) {
        return new Error(error.message);
      }
      return new Error('error');
    }
  },

  /**
   * Get a sample document: either a template or a sample file
   * @param {string} url - The URL of the sample to load.
   * @returns The sample document.
   */
  async loadSample(url: string) {
    try {
      const { data } = await axios.get<string>(url);
      return data;
    } catch (error) {
      logHttpError(error);
      if (axios.isAxiosError(error)) {
        return new Error(error.message);
      }
      return new Error('error');
    }
  },

  /**
   * This function retrieves a document from a given URL .
   * @param {string} url - The `url` parameter is a string that represents the URL of the document.
   * @returns The document.
   */
  async getDocumentFromUrl(url: string) {
    try {
      const { data } = await axios.get<string>(url);
      return data;
    } catch (error) {
      logHttpError(error);
      if (axios.isAxiosError(error)) {
        return new Error(error.message);
      }
      return new Error('error');
    }
  },

  /**
   * It saves a value to local storage, but if the value is not a string, it converts it to a string
   * before saving it
   * @param {string} key - string - The key to save the value under.
   * @param {unknown} value - The value to be stored in local storage.
   */
  saveToLocalStorage<T = unknown>(key: string, value: T) {
    const stringfiedValue = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, stringfiedValue);
  },

  /**
   * It gets a value from local storage, and if it's a JSON string, it parses it into an object
   * @param {string} key - The key to store the value under.
   * @returns The value of the key in localStorage.
   */
  getFromLocalStorage<T = string | Record<string, unknown>>(key: string): T | null {
    const value = localStorage.getItem(key);
    if (!value) return null;

    try {
      const object = JSON.parse(value) as Record<string, unknown>;
      return object as T;
    } catch (error) {
      return value as T;
    }
  },

  /**
   * It removes a key from local storage
   * @param {string} key - The key to be used to store the data in local storage.
   */
  removeFromLocalStorage(key: string) {
    localStorage.removeItem(key);
  },

  /**
   * It deletes all keys from local storage
   */
  clearLocalStorage() {
    localStorage.clear();
  },
};
