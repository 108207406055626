import { createSvgIcon } from '@mui/material/utils';

const BoxOpenIcon = createSvgIcon(
  <path
    d="M14.6,12c-0.4,0-0.8-0.2-1-0.6L12,8.8l-1.6,2.6c-0.2,0.4-0.6,0.6-1,0.6c-0.2,0-0.3,0-0.4,0l-3.4-1v4.4
	c0,0.4,0.2,0.7,0.6,0.8l5.4,1.4c0.3,0.1,0.5,0.1,0.8,0l5.4-1.4c0.4-0.1,0.6-0.4,0.6-0.8V11L15,12C14.9,12,14.8,12,14.6,12z M20,9.2
	l-1.3-2.6c-0.1-0.2-0.2-0.2-0.4-0.2L12,7.2l2.3,3.8c0.1,0.2,0.3,0.2,0.5,0.2l4.9-1.4C20,9.7,20.1,9.4,20,9.2L20,9.2z M5.3,6.6L4,9.2
	C3.9,9.4,4,9.7,4.3,9.8l4.9,1.4c0.2,0,0.4,0,0.5-0.2L12,7.2L5.7,6.4C5.6,6.4,5.4,6.5,5.3,6.6z"
  />,
  'BoxOpen',
);

export default BoxOpenIcon;

export const BoxIcon = createSvgIcon(
  <path d="M1.76,3.63c.12-.24,.38-.38,.64-.34l9.59,1.2,9.59-1.2c.26-.03,.52,.11,.64,.34l1.63,3.27c.35,.7-.02,1.55-.78,1.77l-6.4,1.83c-.54,.16-1.13-.07-1.42-.56l-3.27-5.45-3.27,5.45c-.29,.49-.87,.72-1.42,.56L.91,8.66c-.76-.22-1.13-1.07-.78-1.77L1.76,3.63Zm10.28,3.37l2.15,3.58c.58,.97,1.75,1.43,2.84,1.12l5-1.43v6.55c0,.86-.59,1.61-1.43,1.83l-8,2c-.4,.1-.82,.1-1.22,0l-8-2c-.84-.22-1.43-.97-1.43-1.83v-6.55l5,1.43c1.09,.31,2.26-.15,2.84-1.12l2.15-3.58h.09Z" />,
  'Box',
);

export const BoxOutlinedIcon = createSvgIcon(
  <g>
    <path
      d="M2.24,4.21c.11-.23,.36-.36,.61-.33l9.15,1.14,9.15-1.14c.25-.03,.5,.1,.61,.33l1.56,3.12c.34,.67-.02,1.48-.74,1.69l-6.11,1.75c-.52,.15-1.08-.07-1.35-.53l-3.12-5.19-3.12,5.19c-.28,.46-.83,.68-1.35,.53l-6.1-1.75c-.72-.21-1.08-1.02-.74-1.69l1.55-3.12Z"
      strokeLinejoin="round"
    />
    <path d="M20.78,10.76v5.48c0,.77-.51,1.45-1.25,1.64l-7,1.8c-.35,.09-.72,.09-1.06,0l-7-1.8c-.73-.19-1.25-.87-1.25-1.64v-5.48l-.78-.22v6.24c0,.82,.56,1.54,1.36,1.75l7.63,1.91c.38,.1,.78,.1,1.16,0l7.63-1.91c.8-.2,1.36-.92,1.36-1.74v-6.24l-.78,.22Z" />
    <line x1="12.07" y1="19.6" x2="12.07" y2="9.54" strokeLinecap="round" strokeLinejoin="round" />
  </g>,
  'BoxOutlined',
);
