import { Link, Typography, useMediaQuery, useTheme, Container, Box } from '@mui/material';
import { useAppState } from '@src/overmind';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

export const About = () => {
  const { language } = useAppState().ui;

  const { breakpoints } = useTheme();

  const [content, setContent] = useState('');

  const [contentChangelog, setContentChangelog] = useState('');

  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    loadContent();
  }, [language]);


  useEffect(() => {
    loadContent_Changelog_Gitlab();
  }, []);

  useEffect(() => {
    loadContent_Changelog_Gitlab();
  }, [language]);

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const loadContent = async () => {
    const file = `about_${language.code}.md`;
    const response = await fetch(`./content/${file}`);
    const text = await response.text();
    setContent(text);
  };

  const loadContent_Changelog_Gitlab = async () => {
    // Defaults to english, will always dynamically load from our Gitlab wiki. Also defaults to dev, unless url contains 'stable', then stable changelog will be loaded
    const lang_extension = (language.code == "de-DE") ? 'deutsch' : 'english';
    const branch_type = window.location.host.includes("stable") ? "main" : "dev"
    const file = `Changelog-${branch_type}-${lang_extension}`
    const response = await fetch(`https://code.fbi.h-da.de/api/v4/projects/30163/wikis/${file}`, { mode: "cors", credentials:"same-origin"});
    const text = await response.json();
    // console.log(text.content)
    setContentChangelog(text.content);
  };

  return (
    <>
      <Box py={3}>
        <div style={{overflowY:"auto",maxHeight:"20vh"}}>
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => (
                <Typography
                  component="h4"
                  mb={2}
                  sx={{ fontWeight: 100 }}
                  variant={isMobile ? 'h5' : 'h4'}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Typography component="h5" mb={1} variant={isMobile ? 'h6' : 'h5'} {...props} />
              ),
              p: ({ node, ...props }) => <Typography my={1} {...props} />,
              a: ({ node, ...props }) => (
                <Link underline="hover" target="_blank" rel="noopener noreferrer" {...props} />
              ),
            }}
          >
            {contentChangelog}
          </ReactMarkdown>
        </div>
      </Box>

      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <Typography
              component="h1"
              mb={4}
              sx={{ fontWeight: 300 }}
              variant={isMobile ? 'h3' : 'h2'}
              {...props}
            />
          ),
          h2: ({ node, ...props }) => (
            <Typography component="h2" mb={3} variant={isMobile ? 'h4' : 'h3'} {...props} />
          ),
          h3: ({ node, ...props }) => (
            <Typography component="h3" mb={2.5} variant={isMobile ? 'h5' : 'h4'} {...props} />
          ),
          h4: ({ node, ...props }) => (
            <Typography component="h4" mb={2} variant={isMobile ? 'h6' : 'h5'} {...props} />
          ),
          h5: ({ node, ...props }) => (
            <Typography mb={1.5} variant={isMobile ? 'subtitle1' : 'h6'} {...props} />
          ),
          h6: ({ node, ...props }) => (
            <Typography
              component="h6"
              mb={1}
              paragraph
              variant={isMobile ? 'subtitle2' : 'subtitle1'}
              sx={{ fontWeight: 700 }}
              {...props}
            />
          ),
          p: ({ node, ...props }) => <Typography my={1} {...props} />,
          a: ({ node, ...props }) => (
            <Link underline="hover" target="_blank" rel="noopener noreferrer" {...props} />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </>
  );
};
